import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, FormControl } from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import GetDateString from '../../../services/DateFormat';
import { isAdmin as isAdminState } from 'atoms/atoms';
import { useRecoilState } from 'recoil';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const AffiliateEditForm = ({affiliate, saveCallback}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const [fields, setFields] = useState({
        callSign: affiliate?.callSign || '',
        accountExec: affiliate?.accountExec || '',
        userGroup: affiliate?.userGroup || '',
    });

    const [userGroups, setUserGroups] = useState([]);

    const onTextChange = e => {
        setFields({ ...fields, [e.target.name]: e.target.value });
    }

    const onTextChangeTrim = e => {
        setFields({ ...fields, [e.target.name]: e.target.value.trim() });
    }

    const load = async () => {
        try {
            setUserGroups((await axios.get('/api/users/groups')).data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        load();
    }, []);

    const sendAffiliateEdit = async () => {
        try {
            const where = {};
            const object = fields;

            await axios.post('/api/affiliates/insert', { where, object });

            enqueueSnackbar('Saved Affiliate.');
            if (saveCallback) saveCallback();
        } catch (err) {
            console.log('Could not send request', err);
        }
    }

    return (
        <PageContainer className={classes.root}>
            <TextField
                className={classes.formControl}
                type='text'
                name='callSign'
                label='Call Sign'
                value={fields?.callSign || ''}
                onChange={onTextChangeTrim}
            />

            <br />
            <TextField
                className={classes.formControl}
                type='text'
                name='accountExec'
                label='Account Executive'
                value={fields?.accountExec || ''}
                onChange={onTextChangeTrim}
            />

            <br />
            <FormControl fullWidth>
                <InputLabel id="user-group">User Group</InputLabel>
                <Select
                    labelId="user-group"
                    id="user-group"
                    name='userGroup'
                    value={fields?.userGroup || ''}
                    label='User Group'
                    onChange={onTextChange}
                >
                    {
                        userGroups.map(group => (
                            <MenuItem key={`group-${group}`} value={group}>
                                {group}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

            <br />
            {
                //Save button if admin, OK button if user
                <Button
                    color='primary'
                    onClick={sendAffiliateEdit}
                    variant='contained'
                    className={'square-button'}
                >
                    Save
                </Button>
            }
        </PageContainer>
    );
}

export default AffiliateEditForm;
