import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../node_modules/video-react/dist/video-react.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
import { SnackbarProvider } from 'notistack';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import './App.css';
import RecoilLoader from 'routes/Pages/Components/RecoilLoader';

export const store = configureStore();

const App = () => (
  <RecoilRoot>
    <SnackbarProvider maxSnack={3}>
      <AppContextProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <AppWrapper>
              <Switch>
                <RecoilLoader>
                  <Routes />
                </RecoilLoader>
              </Switch>
            </AppWrapper>
          </ConnectedRouter>
        </Provider>
      </AppContextProvider>
    </SnackbarProvider>
  </RecoilRoot>
);

export default App;
