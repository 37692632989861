import axios from "../../utils/axios"

export const login = (username, password) => new Promise((resolve, reject) => {

    axios.post('/api/users/login',
        {
            username, password
        }
    ).then(resp => {
        resolve(resp.data);
    }).catch(err => {
        reject(err.response.data);
    });

})

export const currentSession = () => new Promise((resolve, reject) => {

    axios.get('/api/users/info'
    ).then(resp => {
        console.log("resp", resp);
        resolve(resp.data);
    }).catch(err => {
        reject(err.response.data);
    });

})


export const logout = () => new Promise((resolve, reject) => {

    axios.post('/api/users/logout')
        .then(resp => {
            resolve(resp);
        }).catch(err => {
            reject(err.response.data);
        });

})