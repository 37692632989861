import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import React, { useEffect } from 'react';
import TableIcons from '../TableIcons';
import MaterialTable from 'material-table';
import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import { useParams, withRouter } from 'react-router';
import ChipList from '../Components/ChipList';
import SpotEditForm from './SpotEditForm';
import axios from 'axios';
import DateFormat from '../Components/DateFormat';
import DeleteForm from '../Components/DeleteForm';
import { affiliateGroupsAtom } from 'atoms/atoms';
import { useRecoilState } from 'recoil';
import ThemedButton from '../Components/ThemedButton';

const SpotsTable = props => {

    const [spots, setSpots] = useState([]);

    const [editFormOpen, setEditFormOpen] = useState(false);
    const [deleteFormOpen, setDeleteFormOpen] = useState(false);
    const [currentEditObject, setCurrentEditObject] = useState({});

    const [affiliateGroups, setAffiliateGroups] = useRecoilState(affiliateGroupsAtom);

    const openEditForm = rowData => {
        setCurrentEditObject(rowData);
        setEditFormOpen(true);
    }

    const openDeleteForm = rowData => {
        setCurrentEditObject(rowData);
        setDeleteFormOpen(true);
    }

    const load = async () => {
        // Do load function if defined
        if (props.loadFunction) props.loadFunction();

        // If we have spots defined, we are done
        if (props?.spots) return;

        // Else, obtain spots
        const SpotFetch = await axios.get('/api/spots/list');
        setSpots(SpotFetch.data);
    }

    useEffect(() => {
        load();

        (async () => {
            try {
                const groupsFetch = await axios.get('/api/affiliates/groups/list');

                setAffiliateGroups(groupsFetch.data);

                console.log('Loaded Recoil variables.');
            } catch (err) {
                console.log('Could not load Recoil variables', err);
            }
        })();
    }, []);

    const arrayWithLabels = array => {
        return array?.map(a => { return { label: a } });
    }

    return (
        <>
            <ThemedButton
                onClick={() => { openEditForm({}); }}
            >
                Add Spot
            </ThemedButton>
            <MaterialTable
                icons={TableIcons}
                columns={[
                    {
                        title: 'Edit',
                        render: rowData => {
                            return (
                                <div>
                                    <Button
                                        className={'rounded-button'}
                                        onClick={() => { openEditForm(rowData); }}
                                        startIcon={<Edit />}
                                    />
                                </div>
                            )
                        }
                    },
                    { title: 'Spot Name', field: 'name' },
                    { title: 'Message Preview', field: 'message' },
                    {
                        title: 'Active Date',
                        field: 'startDate',
                        render: rowData => <DateFormat date={rowData?.startDate} />
                    },
                    {
                        title: 'Kill Date',
                        field: 'expires',
                        render: rowData => <DateFormat date={rowData?.expires} />
                    },
                    {
                        title: 'Active Days',
                        field: 'activeDays',
                        render: rowData => {
                            return <ChipList items={arrayWithLabels(rowData?.activeDays)} />
                        }
                    },
                    {
                        title: 'Day Parts',
                        field: 'dayParts',
                        render: rowData => {
                            return <ChipList items={arrayWithLabels(rowData?.dayParts)} />
                        }
                    },
                    { title: 'Active', render: rowData => rowData?.active ? <>Active</> : <>Inactive</> },
                    { title: 'Reports', render: rowData => rowData?.pulls ? <>{rowData.pulls}</> : <>0</> },
                    { title: 'Performances', render: rowData => rowData?.performances ? <>{rowData.performances}</> : <>0</> },
                    {
                        title: 'Delete',
                        render: rowData => {
                            return (
                                <div>
                                    <Button
                                        className={'rounded-button'}
                                        onClick={() => { openDeleteForm(rowData); }}
                                        startIcon={<Delete />}
                                    />
                                </div>
                            )
                        }
                    },
                    {
                        title: 'As-played',
                        render: rowData => {
                            return (
                                <Button
                                    className={'rounded-button'}
                                    onClick={() => { props.history.push(`/played/spotId/${rowData._id}`) }}
                                    startIcon={<Visibility />}
                                />
                            )
                        }
                    },
                ]}
                data={JSON.parse(JSON.stringify(props?.spots || spots))}
                title={`Affiliate Spots`}
            />

            <Dialog open={editFormOpen} onClose={() => { setEditFormOpen(false); }}>
                <DialogTitle id="form-dialog-title">Modify Spot</DialogTitle>
                <DialogContent>
                    <SpotEditForm
                        spot={currentEditObject}
                        saveCallback={() => {
                            setEditFormOpen(false);
                            load();
                        }}
                    />
                </DialogContent>
            </Dialog>

            <Dialog open={deleteFormOpen} onClose={() => { setDeleteFormOpen(false); }}>
                <DialogTitle id="form-dialog-title">Delete Spot</DialogTitle>
                <DialogContent>
                    <DeleteForm
                        deleteItem={currentEditObject?.name}
                        deleteAction={async () => {
                            await axios.post('/api/spots/delete', { where: { _id: currentEditObject._id } });
                        }}
                        saveCallback={() => {
                            setDeleteFormOpen(false);
                            load();
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default withRouter(SpotsTable);