import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Box, Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';

const Logo = ({ color, ...props }) => {
  const logoUrl = color === 'white' ? '/images/logo-white.png' : '/images/logo.png';
  const logoSymbolUrl = color === 'white' ? '/images/logo-white-symbol.png' : '/images/logo-symbol.png';

  return (
    <Box style={{width: '100%'}} className="pointer" {...props}>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <Hidden xsDown>
            <NavLink to="/">
              <CmtImage src={logoUrl} alt="logo" />
            </NavLink>
          </Hidden>
          <Hidden smUp>
            <NavLink to="/">
              <CmtImage src={logoSymbolUrl} alt="logo" />
            </NavLink>
          </Hidden>
        </Grid>
        <Grid item xs={11}>
          <div style={{margin: 10}}>
            ATMOS Traffic Portal
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Logo;
