
//Takes in array of object ids that map to an affiliate
//Takes in array of affiliates

//Returns array of { label: call sign, data: id }
//of objects that are in the ids array
export default (ids, affiliates, labelProperty) => {
    //Find affiliates that are currently in the group
    const activeAffiliates = ids?.map(a => affiliates?.find(d => d?._id == a)) || [];

    //Add a label and data
    const labels = activeAffiliates?.map(a => ({ label: a?.[labelProperty], data: a?._id }));

    return labels;
}
