import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import GetDateString from '../../../services/DateFormat';
import { isAdmin as isAdminState } from 'atoms/atoms';
import { useRecoilState } from 'recoil';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ChipList from '../Components/ChipList';
import getLabelsFromIds from '../../../services/utils/GetLabelsFromIds';
import removeFromField from '../../../services/utils/RemoveFromField';
import appendToField from '../../../services/utils/AppendToField';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const AffiliateGroupEditForm = ({group, saveCallback}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const [fields, setFields] = useState({
        groupName: group?.groupName || '',
        affiliateIds: group?.affiliateIds || [],
    });

    //List of all affiliates the user group owns
    //And could add to the affiliate group
    const [ownedAffiliates, setOwnedAffiliates] = useState([]);

    const onTextChange = e => {
        setFields({ ...fields, [e.target.name]: e.target.value });
    }

    const load = async () => {
        try {
            const affiliatesFetch = await axios.get('/api/affiliates/list');

            setOwnedAffiliates(affiliatesFetch.data);
        } catch (err) {
            console.log('Could not load affiliates', err);
        }
    }

    useEffect(() => {
        load();
    }, []);

    const sendGroupEdit = async () => {
        try {
            //Setting this to fields will upsert
            const where = group?._id ? { _id: group._id } : fields;

            const object = fields;

            await axios.post('/api/affiliates/groups/update', { where, object });

            enqueueSnackbar('Saved group.');
            if (saveCallback) saveCallback();
        } catch (err) {
            console.log('Could not send request', err);
        }
    }

    return (
        <PageContainer className={classes.root}>
            <TextField
                className={classes.formControl}
                type='text'
                name='groupName'
                label='Group Name'
                value={fields?.groupName || ''}
                onChange={onTextChange}
            />

            <br />
            <InputLabel style={{ textAlign: 'left' }}>Affiliates</InputLabel>
            <Select
                className={classes.formControl}
                name='affiliateIds'
                onChange={e => { appendToField(fields, setFields, 'affiliateIds', e.target.value); }}
                value={fields?.status || ''}
            >
                {
                    ownedAffiliates.map(a => (
                        <MenuItem key={`owned-${a._id}`} value={a._id}>{a.callSign}</MenuItem>
                    ))
                }
            </Select>

            <ChipList
                items={getLabelsFromIds(fields?.affiliateIds, ownedAffiliates, 'callSign')}
                onDelete={i => { removeFromField(fields, setFields, 'affiliateIds', i.data); }}
            />

            <br />
            <Button
                color='primary'
                onClick={sendGroupEdit}
                variant='contained'
                className={'square-button'}
            >
                Save
            </Button>
        </PageContainer>
    );
}

export default AffiliateGroupEditForm;
