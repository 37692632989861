import React, { useContext } from 'react';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from "../../../../../redux/actions";

import AppContext from '../../../contextProvider/AppContextProvider/AppContext';
import CmtVerticalLayout from '../../../../../@coremat/CmtLayouts/Vertical';
import CmtHeader from '../../../../../@coremat/CmtLayouts/Vertical/Header';
import Header from '../../partials/Header';
import CmtSidebar from '../../../../../@coremat/CmtLayouts/Vertical/Sidebar';
import SidebarHeader from '../../partials/SidebarHeader';
import SideBar from '../../partials/SideBar';
import CmtContent from '../../../../../@coremat/CmtLayouts/Vertical/Content';
import ContentLoader from '../../../ContentLoader';
import { SIDEBAR_TYPE } from '../../../../constants/ThemeOptions';
import clsx from 'clsx';
import CmtFooter from '../../../../../@coremat/CmtLayouts/Vertical/Footer';
import Footer from '../../partials/Footer';

const VerticalMinimal = ({ actions, className, children }) => {
  const { drawerBreakPoint, headerType, isSidebarFixed, sidebarStyle, sidebarSize, showFooter } = useContext(AppContext);

  const authState = useSelector(state => state.authState.user);

  return (
    <CmtVerticalLayout
      drawerBreakPoint={drawerBreakPoint}
      sidebarWidth={authState && authState.data ? sidebarSize : 0}
      className={clsx('verticalMinimalLayout', className)}
    >


      {authState && authState.data &&
        <CmtHeader type={headerType}>
          <Header />
        </CmtHeader>

      }

      {authState && authState.data &&

        <CmtSidebar type={SIDEBAR_TYPE.MINI} isSidebarFixed={isSidebarFixed} {...sidebarStyle}>
          <>
            <SidebarHeader />
            <SideBar />
          </>
        </CmtSidebar>
      }

      <CmtContent>
        {children}
        <ContentLoader />
      </CmtContent>
      {
        showFooter && (
          <CmtFooter type="static">
            <Footer />
          </CmtFooter>
        )
      }
    </CmtVerticalLayout >
  );
};

const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(actions, dispath)
});

export default connect(null, mapDispatchToProps)(VerticalMinimal);

