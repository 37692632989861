import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, FormGroup, Grid } from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import GetDateString from '../../../services/DateFormat';
import { affiliateGroupsAtom } from 'atoms/atoms';
import { useRecoilState } from 'recoil';
import { KeyboardDatePicker } from '@material-ui/pickers';
import getLabelsFromIds from '../../../services/utils/GetLabelsFromIds';
import removeFromField from '../../../services/utils/RemoveFromField';
import appendToField from '../../../services/utils/AppendToField';
import ChipList from '../Components/ChipList';
import WeekList from 'services/utils/WeekList';
import DaypartList from 'services/utils/DaypartList';
import VoiceGenderList from 'services/utils/VoiceGenderList';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const SpotEditForm = ({spot, saveCallback}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const [fields, setFields] = useState({
        name: spot?.name || '',
        message: spot?.message || '',
        startDate: spot?.startDate ? new Date(spot.startDate) : new Date(),
        expires: spot?.expires ? new Date(spot.expires) : new Date(),
        affiliateGroups: spot?.affiliateGroups || [],
        activeDays: spot?.activeDays || [],
        dayParts: spot?.dayParts || [],
        voiceGender: spot?.voiceGender || 'Either',
        agencyReferenceCode: spot?.agencyReferenceCode || '',
        spotLength: spot?.spotLength || '',
        active: 'active' in spot ? spot.active : true,
        fixedLength: 'fixedLength' in spot ? spot.fixedLength : false,
    });

    const [ownedGroups, setOwnedGroups] = useRecoilState(affiliateGroupsAtom);

    const onTextChange = e => {
        setFields({ ...fields, [e.target.name]: e.target.value });
    }

    const onNumericChange = e => {
        try {
            const number = parseInt(e.target.value);
            setFields({ ...fields, [e.target.name]: number });
        } catch (err) {
            console.log(err);
        }
    }

    const onCheckboxChange = e => {
        setFields({ ...fields, [e.target.name]: e.target.checked });
    }

    const changeDateField = (name, date) => {
        setFields({ ...fields, [name]: date });
    }

    const sendSpotEdit = async () => {
        try {
            //Setting this to fields will upsert
            const where = spot?._id ? { _id: spot._id } : fields;

            const object = fields;

            await axios.post('/api/spots/update', { where, object });

            enqueueSnackbar('Saved spot.');
            if (saveCallback) saveCallback();
        } catch (err) {
            console.log('Could not send request', err);
        }
    }

    return (
        <PageContainer className={classes.root}>
            <TextField
                className={classes.formControl}
                type='text'
                name='name'
                label='Spot Name'
                value={fields?.name || ''}
                onChange={onTextChange}
            />

            <br />
            <TextField
                className={classes.formControl}
                type='text'
                name='message'
                label='Spot Message'
                value={fields?.message || ''}
                onChange={onTextChange}
                multiline
            />

            <br />
            <TextField
                className={classes.formControl}
                type='text'
                name='agencyReferenceCode'
                label='Agency Reference Code'
                value={fields?.agencyReferenceCode || ''}
                onChange={onTextChange}
            />

            <br />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        margin="normal"
                        label="Start Date"
                        format="MM/DD/yyyy"
                        value={fields?.startDate || new Date()}
                        onChange={date => { changeDateField('startDate', date); }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        margin="normal"
                        label="Expires"
                        format="MM/DD/yyyy"
                        value={fields?.expires || new Date()}
                        onChange={date => { changeDateField('expires', date); }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
            </Grid>

            <br />
            <InputLabel style={{ textAlign: 'left' }}>Affiliate Groups</InputLabel>
            <Select
                className={classes.formControl}
                name='affiliateGroups'
                onChange={e => { appendToField(fields, setFields, 'affiliateGroups', e.target.value); }}
                value={fields?.status || ''}
            >
                {
                    ownedGroups.map(a => (
                        <MenuItem key={`owned-group-${a?._id}`} value={a?._id}>{a?.groupName}</MenuItem>
                    ))
                }
            </Select>

            <ChipList
                items={getLabelsFromIds(fields?.affiliateGroups, ownedGroups, 'groupName')}
                onDelete={i => { removeFromField(fields, setFields, 'affiliateGroups', i.data); }}
            />

            <br />
            <FormGroup row>
                {
                    WeekList.map(d => (
                        <FormControlLabel
                            key={d}
                            control={
                                <Checkbox
                                    checked={fields?.activeDays?.includes(d) || false}
                                    onChange={e => {
                                        !e.target.checked ? removeFromField(fields, setFields, 'activeDays', d) : 
                                        appendToField(fields, setFields, 'activeDays', d)
                                    }}
                                />
                            }
                            label={d}
                        />
                    ))
                }
            </FormGroup>

            <br />
            <FormGroup row>
                {
                    DaypartList.map(d => (
                        <FormControlLabel
                            key={d}
                            control={
                                <Checkbox
                                    checked={fields?.dayParts?.includes(d) || false}
                                    onChange={e => {
                                        !e.target.checked ? removeFromField(fields, setFields, 'dayParts', d) : 
                                        appendToField(fields, setFields, 'dayParts', d)
                                    }}
                                />
                            }
                            label={d}
                        />
                    ))
                }
            </FormGroup>

            <br />
            <InputLabel style={{ textAlign: 'left' }}>Voice Name</InputLabel>
            <Select
                className={classes.formControl}
                name='voiceGender'
                onChange={onTextChange}
                value={fields?.voiceGender || ''}
            >
                {
                    VoiceGenderList.map(v => (
                        <MenuItem
                            key={`voice-gender-${v?.name}`}
                            value={`${v?.name}-${v?.cloud}`}>
                            {v?.name || 'N/A'}
                        </MenuItem>
                    ))
                }
            </Select>

            <FormControlLabel
                control={
                    <Checkbox
                        name='active'
                        checked={fields?.active}
                        onChange={onCheckboxChange}
                    />
                }
                label={'Active'}
            />

            <br />
            <FormControlLabel
                control={
                    <Checkbox
                        name='fixedLength'
                        checked={fields?.fixedLength}
                        onChange={onCheckboxChange}
                    />
                }
                label={'Conform to Length'}
            />
            {
                fields?.fixedLength ?
                (
                    <>
                        <br />
                        <TextField
                            className={classes.formControl}
                            type='text'
                            name='spotLength'
                            label='Spot Length (in ms)'
                            value={fields?.spotLength || ''}
                            onChange={onNumericChange}
                        />
                    </>
                ) : <></>
            }

            <br />
            <Button
                color='primary'
                onClick={sendSpotEdit}
                variant='contained'
                className={'square-button'}
            >
                Save
            </Button>
        </PageContainer>
    );
}

export default SpotEditForm;
