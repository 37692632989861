import React from 'react';
import { useEffect } from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import { affiliateGroupsAtom, affiliatesAtom } from 'atoms/atoms';
import { useRecoilState } from 'recoil';

const RecoilLoader = props => {

    const [affiliates, setAffiliates] = useRecoilState(affiliatesAtom);
    const [affiliateGroups, setAffiliateGroups] = useRecoilState(affiliateGroupsAtom);

    useEffect(() => {
        props.history.listen(async () => {
            
            try {
                const affiliatesFetch = await axios.get('/api/affiliates/list');
                const groupsFetch = await axios.get('/api/affiliates/groups/list');
                
                setAffiliates(affiliatesFetch.data);
                setAffiliateGroups(groupsFetch.data);

                console.log('Loaded Recoil variables.');
            } catch (err) {
                console.log('Could not load Recoil variables', err);
            }
        });
    }, []);

    return (
        props.children
    );
}

export default withRouter(RecoilLoader);