import { atom } from 'recoil';

export const isAdmin = atom({
    key: 'isAdmin',
    default: false,
});

export const affiliatesAtom = atom({
    key: 'affiliates',
    default: [],
});

export const affiliateGroupsAtom = atom({
    key: 'affiliateGroups',
    default: [],
});

export const settings = atom({
    key: 'settings',
    default: {},
});
