import { Chip } from '@material-ui/core';
import React from 'react';

const ChipList = props => {

    const { prependKey } = props;

    return (
        <>
            {
                //If we have props and an items prop, render a list of chips
                //If we do not, render nothing
                props?.items ? (
                    <>
                        {
                            props.items.map((i, index) => {
                                {
                                    //Only render if we have a label
                                    return i?.label ? (
                                        <Chip
                                            key={`${prependKey || ''}-${index}-${new Date().toISOString()}`}
                                            //If 'i' has a label, render that instead
                                            label={i?.label ? i.label : ''}
                                            onDelete={props?.onDelete ? () => { props.onDelete(i); } : undefined}
                                        />
                                    ) : <div key={`${prependKey || ''}-${index}-${new Date().toISOString()}`}></div>
                                }
                            })
                        }
                    </>
                ) : <></>
            }
        </>
    );
}

export default ChipList;