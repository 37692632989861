/// RaviB Requried references for redux
import React from 'react';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from "../../../redux/actions";
//// End of required reference for redux

import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Avatar, Button, makeStyles, TextField, CssBaseline, Link, Grid, Box, Typography, Container, Paper } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withSnackbar } from 'notistack';
import CmtImage from '@coremat/CmtImage';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {/* {'Sidecar © '} */}
            <Link color="inherit" href="https://www.summittechgroup.com/">
                ©2021 Summit Technology Group
            </Link>{' '}
            {/* {new Date().getFullYear()}
            {'.'} */}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(6)
    },
    paper: {
        marginTop: theme.spacing(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Login = (props) => {
    const classes = useStyles();

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const handleUsernameChange = e => {
        setUsername(e.target.value);
    }

    const handlePasswordChange = e => {
        setPassword(e.target.value);
    }

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            sendLogin();
        }
    }

    //// RaviB get authReducers value everytime when data has been changed
    const authState = useSelector(state => state.authState.user);

    //// RaviB useEffect will be used to avoid loops for state changes detection and trigger only when authState has been updated. 
    useEffect(() => {
        if (authState.error) {
            props.enqueueSnackbar(authState.error, { variant: 'error' })
        }
    }, [authState.error])


    useEffect(() => {
        if (authState?.data?.username) {
            props.enqueueSnackbar(`Logged in as ${authState?.data?.username}`, { variant: 'success' });
            props.history.push('/transcription')
        }
    }, [authState.data])

    const sendLogin = () => {
        /// RaviB Since we have connected this view with redux action at the bottom of view, we should get all the actions here
        props.actions.signInUser(username, password, props)
    }

    const logoUrl = '/images/login-logo.png';

    return (

        <Container component="main" maxWidth="xs">
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >

                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.root}>
                        <CssBaseline />
                        <br />
                        <CmtImage src={logoUrl} alt="Side car cloud" />
                        <br />
                        <br />

                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
            </Typography>
                        <form className={classes.form} noValidate>

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onKeyDown={handleKeyDown}
                                autoFocus
                                label="Username"
                                type="text"
                                name='username'
                                value={username || ''}
                                onChange={handleUsernameChange}
                                autoComplete='off'
                            />

                            <TextField
                                onKeyDown={handleKeyDown}
                                onChange={handlePasswordChange}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                style={{ color: 'white' }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                size="large"
                                className={classes.submit}
                                onClick={sendLogin}
                                disabled={authState.loading}
                            >
                                Sign In
                            </Button>
                            <Grid container style={{ marginTop: 8 }}>
                                <Grid item xs>
                                    <Link href="#" style={{ fontSize: 12 }}>
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/user-register" style={{ fontSize: 12 }}>
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                        <br />
                        <Box mt={8}>
                            <Copyright />
                        </Box>
                    </Paper >
                </Grid>
            </Grid>
        </Container >

    );
}

//// RaviB binding actions with the view, this will enable actions access on the page
const mapDispatchToProps = (dispath) => ({
    actions: bindActionCreators(actions, dispath)
});

export default connect(null, mapDispatchToProps)(withSnackbar(Login));
//// End of Binding actions


///// Note: this should be always be done at Parent most view only, we should avoid binding sub components with actions