import React, { useContext, useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from "../../../redux/actions";

import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { IntlProvider } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, jssPreset, StylesProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { create } from 'jss';
import rtl from 'jss-rtl';
import AppContext from '../contextProvider/AppContextProvider/AppContext';
import AppLocale from '../../../i18n';
import AppLayout from '../AppLayout';
import CssBaseline from '@material-ui/core/CssBaseline';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const AppWrapper = ({ actions, match, location, children }) => {
  const { theme, locale, direction } = useContext(AppContext);
  const currentAppLocale = AppLocale[locale.locale];
  useEffect(() => {
    if (direction === 'rtl') {
      document.body.setAttribute('dir', 'rtl');
    } else {
      document.body.setAttribute('dir', 'ltr');
    }
  }, [direction]);

  useEffect(() => {
    actions.checkSession()
  }, [])

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ThemeProvider theme={createMuiTheme(theme)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <StylesProvider jss={jss}>
            <CssBaseline />
            <AppLayout>{children}</AppLayout>
          </StylesProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(actions, dispath)
});


export default connect(null, mapDispatchToProps)(AppWrapper)

// export default AppWrapper;
