import { Button } from '@material-ui/core';
import React from 'react';

const ThemedButton = props => {
    return (
        <Button
            {...props}
            style={{ marginTop: 10, marginBottom: 10 }}
            color='secondary'
            variant='contained'
        >
            {props.children}
        </Button>
    );
}

export default ThemedButton;