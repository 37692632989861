import React from 'react';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ThemedButton from '../Components/ThemedButton';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const UserEditForm = props => {

    const classes = useStyles();

    const formik = useFormik({
        initialValues: props.editObject,
        onSubmit: async () => {
            try {
                const { _id } = formik.values;

                // Clear id field, Mongo cannot update this
                delete formik.values._id;

                await axios.post('/api/users/update', {
                    where: { _id },
                    object: formik.values,
                });

                props.saveCallback();
            } catch (err) {
                console.log('Could not update user', err);
            }
        },
    });

    return (
        <PageContainer>
            
            <TextField
                className={classes.formControl}
                type='text'
                name='username'
                label='Username'
                value={formik.values.username}
                onChange={formik.handleChange}
            />

            <TextField
                className={classes.formControl}
                type='text'
                name='userGroup'
                label='Group'
                value={formik.values.userGroup}
                onChange={formik.handleChange}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        name='isAdmin'
                        checked={formik.values.isAdmin}
                        onChange={formik.handleChange}
                    />
                }
                label={'Admin'}
            />

            <br />

            <ThemedButton
                onClick={formik.handleSubmit}
            >
                Save
            </ThemedButton>

        </PageContainer>
    );
}

export default UserEditForm;