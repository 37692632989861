import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import axios from 'axios';
import MaterialTable from 'material-table';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { ConfirmationNumber, Delete, DevicesOther, Edit, QueryBuilder, Visibility } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import TableIcons from './TableIcons';
import { useParams } from 'react-router';
import GetDateString from '../../services/DateFormat';
import DashboardCard from './Components/DashboardCard';
import Notifications from '@material-ui/icons/Notifications';
import {
    isAdmin as isAdminState,
    settings as settingsState,
} from 'atoms/atoms';
import { useRecoilState } from 'recoil';

const Dashboard = props => {

    const [serials, setSerials] = useState([]);
    const [isAdmin, setIsAdmin] = useRecoilState(isAdminState);
    const [settings, setSettings] = useRecoilState(settingsState);

    useEffect(() => {
        (async () => {
            try {
                const serialsFetch = await axios.get('/api/valid-serials/user-list');
                const settingsFetch = await axios.post('/api/settings/read');
                const adminFetch = await axios.get('/api/users/is-admin');
                const towerFetch = await axios.get('/api/towers/list');
                const notamFetch = await axios.get('/api/notam/user-list');

                setSerials(serialsFetch.data);
                setIsAdmin(adminFetch.data);
                setSettings(settingsFetch?.data[0]);
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    return (
        <PageContainer>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <DashboardCard
                        backgroundColor={'#b6d7a8'}
                        header={`${serials?.length || 0}`}
                        title={'Licensed Devices'}
                        icon={<DevicesOther className={'large-icon'} />}
                    />
                </Grid>
                {/* <Grid item xs={3}>
                    <DashboardCard
                        backgroundColor={'#f9cb9c'}
                        header={`${notams?.filter(n => n?.status != 'Closed')?.length || 0}`}
                        title={'Open NOTAMs'}
                        icon={<ConfirmationNumber className={'large-icon'} />}
                    />
                </Grid> */}
                <Grid item xs={3}>
                    <DashboardCard
                        backgroundColor={'#dd7e6b'}
                        header={`${serials?.length || 0}`}
                        title={'Aux Alarms within 30 Days'}
                        icon={<Notifications className={'large-icon'} />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DashboardCard
                        backgroundColor={'#a4c2f4'}
                        header={`${serials?.length || 0}`}
                        title={'Most Recent Check-in'}
                        icon={<QueryBuilder className={'large-icon'} />}
                    />
                </Grid>
            </Grid>

        </PageContainer>
    );
}

export default Dashboard;