/// RaviB Requried references for redux
import React from 'react';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../redux/actions';
//// End of required reference for redux

import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Avatar, Button, makeStyles, TextField, CssBaseline, Link, Grid, Box, Typography, Container, Paper } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withSnackbar } from 'notistack';
import CmtImage from '@coremat/CmtImage';
import axios from 'axios';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {/* {'Sidecar © '} */}
            <Link color="inherit" href="https://www.summittechgroup.com/">
                ©2021 Summit Technology Group
            </Link>{' '}
            {/* {new Date().getFullYear()}
            {'.'} */}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(6)
    },
    paper: {
        marginTop: theme.spacing(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Login = (props) => {
    const classes = useStyles();

    const [userGroup, setUserGroup] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordConfirm, setPasswordConfirm] = React.useState('');

    const handleUserGroupChange = e => {
        setUserGroup(e.target.value);
    }

    const handleUsernameChange = e => {
        setUsername(e.target.value);
    }

    const handlePasswordChange = e => {
        setPassword(e.target.value);
    }

    const handlePasswordConfirmChange = e => {
        setPasswordConfirm(e.target.value);
    }

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            sendRegister();
        }
    }

    const sendRegister = () => {
        if (password == '' || passwordConfirm == '' || (password != passwordConfirm)) {
            props.enqueueSnackbar('Could not register, passwords must match and not be blank');

            return;
        }

        if (userGroup.trim() == '') {
            props.enqueueSnackbar('Could not register, user group must be non-empty');

            return;
        }

        axios.post('/api/users/register', { username, password, confirmPassword: passwordConfirm, userGroup }).then(resp => {
            if (resp.status === 200) {
                props.history.push('/login');
            }
        }).catch(err => {
            props.enqueueSnackbar('Could not send register ' + err);
        });
    }

    const logoUrl = '/images/login-logo.png';

    return (

        <Container component="main" maxWidth="xs">
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >

                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.root}>
                        <CssBaseline />
                        <br />
                        <CmtImage src={logoUrl} alt="Side car cloud" />
                        <br />
                        <br />

                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Register
            </Typography>
                        {/* <form className={classes.form} noValidate> */}

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onKeyDown={handleKeyDown}
                                autoFocus
                                label="User Group"
                                type="text"
                                name='userGroup'
                                value={userGroup || ''}
                                onChange={handleUserGroupChange}
                                autoComplete='off'
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onKeyDown={handleKeyDown}
                                label="Username"
                                type="text"
                                name='username'
                                value={username || ''}
                                onChange={handleUsernameChange}
                                autoComplete='off'
                            />

                            <TextField
                                onKeyDown={handleKeyDown}
                                onChange={handlePasswordChange}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />

                            <TextField
                                onKeyDown={handleKeyDown}
                                onChange={handlePasswordConfirmChange}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="passwordConfirm"
                                label="Password Confirm"
                                type="password"
                                id="passwordConfirm"
                                autoComplete="current-password-confirm"
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                size="large"
                                className={classes.submit}
                                onClick={sendRegister}
                                className={'square-button'}
                                // disabled={authState.loading}
                            >
                                Register
                            </Button>
                        <br />
                        <Box mt={8}>
                            <Copyright />
                        </Box>
                    </Paper >
                </Grid>
            </Grid>
        </Container >

    );
}

//// RaviB binding actions with the view, this will enable actions access on the page
const mapDispatchToProps = (dispath) => ({
    actions: bindActionCreators(actions, dispath)
});

export default connect(null, mapDispatchToProps)(withSnackbar(Login));
//// End of Binding actions


///// Note: this should be always be done at Parent most view only, we should avoid binding sub components with actions