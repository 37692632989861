import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import React from 'react';
import TableIcons from '../TableIcons';
import MaterialTable from 'material-table';
import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import { withRouter } from 'react-router';
import axios from 'axios';
import { useEffect } from 'react';
import AffiliateGroupEditForm from './AffiliateGroupEditForm';
import { affiliateGroupsAtom } from 'atoms/atoms';
import { useRecoilState } from 'recoil';
import DeleteForm from '../Components/DeleteForm';
import ThemedButton from '../Components/ThemedButton';

const AffiliateGroups = props => {

    const [groups, setGroups] = useRecoilState(affiliateGroupsAtom);

    const [editFormOpen, setEditFormOpen] = useState(false);
    const [currentEditObject, setCurrentEditObject] = useState({});
    const [deleteFormOpen, setDeleteFormOpen] = useState(false);

    const openEditForm = rowData => {
        setCurrentEditObject(rowData);
        setEditFormOpen(true);
    }

    const openDeleteForm = rowData => {
        setCurrentEditObject(rowData);
        setDeleteFormOpen(true);
    }

    const load = async () => {
        try {
            const groupsFetch = await axios.get('/api/affiliates/groups/list');

            //We later filter out groups that are marked as single
            //For now, we just update the atom that stores all groups
            setGroups(groupsFetch.data);
        } catch (err) {
            console.log('Could not load groups', err);
        }
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <PageContainer>
            <ThemedButton onClick={() => { openEditForm({}); }}>
                Add Group
            </ThemedButton>
            <MaterialTable
                icons={TableIcons}
                columns={[
                    {
                        title: 'Edit',
                        render: rowData => {
                            return (
                                <div>
                                    <Button
                                        className={'rounded-button'}
                                        onClick={() => { openEditForm(rowData); }}
                                        startIcon={<Edit />}
                                    />
                                </div>
                            )
                        }
                    },
                    { title: 'Group Name', field: 'groupName' },
                    {
                        title: 'Delete',
                        render: rowData => {
                            return (
                                <div>
                                    <Button
                                        className={'rounded-button'}
                                        onClick={() => { openDeleteForm(rowData); }}
                                        startIcon={<Delete />}
                                    />
                                </div>
                            )
                        }
                    },
                ]}
                //Filter out single items
                data={JSON.parse(JSON.stringify(groups.filter(a => !a?.single)))}
                title='Affiliate Groups'
            />

            <Dialog open={editFormOpen} onClose={() => { setEditFormOpen(false); }}>
                <DialogTitle id="form-dialog-title">Modify Group</DialogTitle>
                <DialogContent>
                    <AffiliateGroupEditForm
                        group={currentEditObject}
                        saveCallback={() => {
                            setEditFormOpen(false);
                            load();
                        }}
                    />
                </DialogContent>
            </Dialog>

            <Dialog open={deleteFormOpen} onClose={() => { setDeleteFormOpen(false); }}>
                <DialogTitle id="form-dialog-title">Delete Spot</DialogTitle>
                <DialogContent>
                    <DeleteForm
                        deleteItem={currentEditObject?.groupName}
                        deleteAction={async () => {
                            await axios.post('/api/affiliates/groups/delete', { where: { _id: currentEditObject._id } });
                        }}
                        saveCallback={() => {
                            setDeleteFormOpen(false);
                            load();
                        }}
                    />
                </DialogContent>
            </Dialog>
        </PageContainer>
    );
}

export default  withRouter(AffiliateGroups);