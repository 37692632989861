import { InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ThemedButton from '../Components/ThemedButton';
import AsPlayedTable from './AsPlayedTable';

const AsPlayed = () => {

    // Type of played fetch we will do, and the id to search for
    const { searchType, searchValue } = useParams();

    const [played, setPlayed] = useState([]);
    const [affiliates, setAffiliates] = useState([]);
    const [spots, setSpots] = useState([]);

    // Get time properties of current date in order to calculate last month
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    const [filterData, setFilterData] = useState({
        fromDate: new Date(year, Math.max(month - 1, 0), day, hours, minutes),
        toDate: new Date(),
        fromTime: new Date(year, month, day, 0, 0),
        toTime: new Date(year, month, day, 23, 59),
        affiliate: 'All',
        type: 'All',
        [searchType]: searchValue,
    });

    const handleFilterChange = (prop, value) => {
        setFilterData({ ...filterData, [prop]: value });
    }

    // When filter data changes, we reload with new data
    useEffect(() => {
        loadFromFilter();
    }, [filterData]);

    const loadFromFilter = async () => {
        try {
            console.log('Reloading with new filter...');

            let { fromDate, toDate, affiliate, type, fromTime, toTime } = filterData;

            const where = {
                date: {
                    $gte: fromDate,
                    $lte: toDate
                },
                type,
                affiliate,
                [searchType]: searchValue
            }

            // Do not check against if 'All'
            if (type == 'All') delete where.type;
            if (affiliate == 'All') delete where.affiliate;

            console.log('where', where);
            const playedFetch = await axios.post('/api/played', { where });
            const playedData = playedFetch.data;

            // Filter by time
            // If date time is < from or > to, we filter it out

            const filtered = playedData.filter(p => {
                const date  = new Date(p.date);

                fromTime = new Date(fromTime.toISOString());
                toTime = new Date(toTime.toISOString());
                
                const fromTimeDate = new Date(date.toISOString());
                fromTimeDate.setHours(fromTime.getHours());
                fromTimeDate.setMinutes(fromTime.getMinutes());

                const toTimeDate = new Date(date.toISOString());
                toTimeDate.setHours(toTime.getHours());
                toTimeDate.setMinutes(toTime.getMinutes());

                return (date >= fromTimeDate && date <= toTimeDate);
            });


            setPlayed(filtered);
        } catch (err) {
            console.log('Could not load from filter', err);
        }
    }

    const downloadCSV = () => {
        try {
            const headers = ['Spot Id', 'Spot Name', 'Date', 'Type', 'Agency Ref Code'];
            let csvContent = '';

            // Add header to CSV file
            csvContent += headers.join(',') + ',\n';

            // Loop through objects, get their content from header keys
            played.forEach(p => {
                const spot = spots?.find(s => s._id == p.spotId);

                const spotId = spot?._id || 'N/A';
                const spotName = spot?.name || 'N/A';
                const agencyReferenceCode = spot?.agencyReferenceCode || 'N/A';

                csvContent += spotId + ',';
                csvContent += spotName + ',';
                csvContent += p.date + ',';
                csvContent += p.type + ',';
                csvContent += agencyReferenceCode + ',';
                csvContent += '\n';
            });

            // Download string as CSV
            const element = document.createElement('a');
            const file = new Blob([csvContent], {type: 'text/csv'});
            element.href = URL.createObjectURL(file);
            element.download = 'as-played.csv';
            document.body.appendChild(element);
            element.click();
        } catch (err) {
            console.log('Could not convert table to CSV', err);
        }
    }

    const load = async () => {
        try {
            const playedFetch = await axios.post('/api/played', { where: { [searchType]: searchValue } });

            setPlayed(playedFetch.data);

            const affiliateFetch = await axios.get('/api/affiliates/list');
            setAffiliates(affiliateFetch.data);

            const spotFetch = await axios.get('/api/spots/list');
            setSpots(spotFetch.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <>
        <div style={{ width: '100px' }}>
            <ThemedButton onClick={downloadCSV}>
                CSV
            </ThemedButton>
        </div>
            <Paper style={{ padding: 10 }}>

                <div className="table-filters">
                    <KeyboardDatePicker
                        label='From'
                        onChange={(date) => {
                            handleFilterChange('fromDate', date);
                        }}
                        value={filterData.fromDate}
                    />
                    <KeyboardDatePicker
                        label='To'
                        onChange={(date) => {
                            handleFilterChange('toDate', date);
                        }}
                        value={filterData.toDate}
                    />
                    <TimePicker
                        label='From Time'
                        onChange={(date) => {
                            handleFilterChange('fromTime', date);
                        }}
                        value={filterData.fromTime}
                        InputProps={{
                            endAdornment: <AccessTime style={{ color: '#757575' }} />
                        }}
                    />
                    <TimePicker
                        label='To Time'
                        onChange={(date) => {
                            handleFilterChange('toTime', date);
                        }}
                        value={filterData.toTime}
                        InputProps={{
                            endAdornment: <AccessTime style={{ color: '#757575' }} />
                        }}
                    />
                    <div className="table-filter">
                        <InputLabel style={{ textAlign: 'left' }}>Affiliate</InputLabel>
                        <Select
                            onChange={e => { handleFilterChange('affiliate', e.target.value); }}
                            value={filterData?.affiliate || ''}
                        >
                            {
                                [
                                    {
                                        callSign: 'All',
                                        _id: 'All'
                                    },
                                    ...affiliates
                                ]
                                .map(affiliate => <MenuItem key={affiliate._id} value={affiliate.callSign}>{affiliate.callSign}</MenuItem>)
                            }
                        </Select>
                    </div>
                    <div className="table-filter">
                        <InputLabel style={{ textAlign: 'left' }}>Type</InputLabel>
                        <Select
                            onChange={e => { handleFilterChange('type', e.target.value); }}
                            value={filterData?.type || ''}
                        >
                            <MenuItem value={'All'}>All</MenuItem>
                            <MenuItem value={'Pulled'}>Pulled</MenuItem>
                            <MenuItem value={'Performed'}>Performed</MenuItem>
                        </Select>
                    </div>
                </div>

                <AsPlayedTable
                    tableTitle={searchType == 'spotId' ? `As-played spot: ${spots?.find(s => s._id == searchValue)?.name || ''}` : `As-played affiliate: ${searchValue}`}
                    nameColumnTitle={searchType == 'spotId' ? 'Affiliate' : 'Spot Id'}
                    nameColumnField={searchType == 'spotId' ? 'affiliate' : 'spotId'}
                    data={played}
                    spots={spots}
                />

            </Paper>
        </>
    );
}

export default AsPlayed;