import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const DeleteForm = ({deleteItem, deleteAction, saveCallback}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();


    const sendDelete = async () => {
        try {
            await deleteAction();

            enqueueSnackbar('Deleted.');
            if (saveCallback) saveCallback();
        } catch (err) {
            console.log('Could not send request', err);
        }
    }

    return (
        <PageContainer className={classes.root}>
            <p>
                Are you sure you want to delete <strong>"{deleteItem}"</strong>? This action cannot be undone.
            </p>

            <br />
            <Button
                color='primary'
                onClick={sendDelete}
                variant='contained'
                className={'square-button'}
            >
                Delete
            </Button>
        </PageContainer>
    );
}

export default DeleteForm;
