
export default (fields, setFields, name, value) => {
    //Create concat array
    const concat = fields[name].concat(value)
    
    //Make unique
    const uniqueConcat = Array.from(new Set(concat));

    setFields({ ...fields, [name]: uniqueConcat });
}
