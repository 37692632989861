import React, { useEffect } from 'react';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '../../../../utils/IntlMessages';
import { Traffic, Radio, Group } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();

  const navigationMenus = [
    {
      name: "", //<IntlMessages id={'sidebar.main'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'pages.affiliates'} />,
          icon: <Radio />,
          type: 'item',
          link: '/affiliates',
        },
        {
          name: <IntlMessages id={'pages.affiliateGroups'} />,
          icon: <Group />,
          type: 'item',
          link: '/affiliate-groups',
        },
        {
          name: <IntlMessages id={'pages.spots'} />,
          icon: <Traffic />,
          type: 'item',
          link: '/spots',
        },
      ],
    },
  ];

  navigationMenus[0].children.forEach(child => {
    if (child?.envVar === 'false') {
      child.link = 'not-licensed'
    }
  })

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
