import React, { useEffect } from 'react';
import TableIcons from '../TableIcons';
import MaterialTable from 'material-table';
import { useState } from 'react';
import DateFormat from '../Components/DateFormat';
import { Chip } from '@material-ui/core';

const AsPlayedTable = ({ tableTitle, data, spots, nameColumnField, nameColumnTitle }) => {
    return (
        <MaterialTable
            icons={TableIcons}
            columns={[
                // Name column should be either affiliate or spot depending on what we are listing
                { title: nameColumnTitle || 'Affiliate', field: nameColumnField || 'affiliate' },
                { title: 'Spot Name', render: rowData => <>{spots?.find(s => s._id == rowData?.spotId)?.name || 'N/A'}</> },
                { title: 'Agency Reference Code', render: rowData => <>{spots?.find(s => s._id == rowData?.spotId)?.agencyReferenceCode || 'N/A'}</> },
                { title: 'Date', render: rowData => <DateFormat date={rowData?.date} time /> },
                { title: 'Type', render: rowData => <Chip label={rowData.type} className={`type-chip-${rowData.type}`} /> },
            ]}
            data={data}
            title={tableTitle}
        />
    );
}

export default AsPlayedTable;