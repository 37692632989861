import * as userAuthService from "../services/authServices";
import * as actionTypes from "./actionTypes";

const loginSuccess = user => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        payload: user
    };
};

const loginRequest = () => {
    return {
        type: actionTypes.LOGIN_REQUEST
    };
};

const loginFailure = error => {
    return {
        type: actionTypes.LOGIN_FAILURE,
        payload: error
    };
};


export const signInUser = (
    email,
    password,
    props
) => async dispatch => {

    dispatch(loginRequest());
    userAuthService.login(
        email,
        password
    ).then(resolve => {

        userAuthService.currentSession()
            .then(userResponse => {
                console.log("userResponse", userResponse)
                dispatch(loginSuccess(userResponse));
                props.history.push('/affiliates');
            }).catch(error => {
                console.error("Invalid login", error)
                dispatch(loginFailure(`Could not login as ${email}`));
            });

    }).catch(error => {
        console.error("Invalid login", error)
        dispatch(loginFailure(`Could not login as ${email}`));
    });

};


export const checkSession = () => async dispatch => {
    dispatch(loginRequest());
    userAuthService.currentSession()
        .then(userResponse => {
            console.log("userResponse", userResponse)
            dispatch(loginSuccess(userResponse));
        }).catch(error => {
            console.error("Check session error", error)
            dispatch(loginFailure("Session expired"));
        });
};




/////////////////// Log out sectiob

const logoutSuccess = user => {
    return {
        type: actionTypes.LOGOUT_SUCCESS,
        payload: user
    };
};

const logoutRequest = () => {
    return {
        type: actionTypes.LOGOUT_REQUEST
    };
};

const logoutFailure = error => {
    return {
        type: actionTypes.LOGOUT_FAILURE,
        payload: error
    };
};


export const signOutUser = () => async dispatch => {
    dispatch(logoutRequest());
    userAuthService.logout()
        .then(resolve => {
            dispatch(logoutSuccess());
        }).catch(error => {
            dispatch(logoutFailure(error));
        });

};