import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import React, { useEffect } from 'react';
import TableIcons from '../TableIcons';
import MaterialTable from 'material-table';
import { useState } from 'react';
import { Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useParams, withRouter } from 'react-router';
import ChipList from '../Components/ChipList';
import SpotsTable from '../Spots/SpotsTable';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { affiliatesAtom } from 'atoms/atoms';

const AffiliateView = props => {

    const { id } = useParams();

    const [spots, setSpots] = useState([]);
    const [affiliates, setAffiliates] = useRecoilState(affiliatesAtom);

    const load = async () => {
        try {
            const groupFetch = await axios.get(`/api/affiliates/groups/find/${id}`);
            const groups = groupFetch.data;

            let spotsFromGroups = [];

            for (let i = 0; i < groups?.length; i++) {
                const group = groups[i];

                const spotsFetch = await axios.get(`/api/spots/group/${group?._id}`);
                const spotsFound = spotsFetch.data;

                spotsFromGroups = spotsFromGroups.concat(spotsFound);
            }

            setSpots(spotsFromGroups);
        } catch (err) {
            console.log('Could not load spots for affiliate', err);
        }
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <PageContainer>
            <h1>{affiliates?.find(a => a?._id == id)?.callSign || ''}</h1>
            <SpotsTable spots={spots} loadFunction={load} />
        </PageContainer>
    );
}

export default AffiliateView;