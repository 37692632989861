import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import actions from "../redux/actions";

import Error404 from './Pages/404';
import Login from './Pages/Login';
import Logout from './Pages/Logout';
import UserRegistration from './Pages/UserRegistration';
import Settings from './Pages/Settings';
import Redirect from './Pages/Redirect';
import Dashboard from './Pages/Dashboard';
import Affiliates from './Pages/Affiliates/Affiliates';
import AffiliateView from './Pages/Affiliates/AffiliateSpots';
import Spots from './Pages/Spots/Spots';
import AffiliateGroups from './Pages/Affiliates/AffiliateGroups';
import Users from './Pages/Users/Users';
import AsPlayed from './Pages/AsPlayed/AsPlayed';

const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const RestrictedRoute = ({ component: Component, ...rest }) => {

  const classes = useStyles();

  const authState = useSelector(state => state.authState.user);

  if (authState.loading) {
    return (
      <Box className={classes.circularProgressRoot}>
        <CircularProgress />
      </Box>
    );
  }

  if (authState.data) {
    return (
      <Route
        {...rest}
        render={props =>
          (<Component {...props} />)
        }
      />
    );
  }

  return (<Redirect to={'/login'} />)
};

const Routes = (props) => {

  const authState = useSelector(state => state.authState.user);
  const location = useLocation();

  // useEffect(() => {

  //   if (authState.data) {
  //     if (location.pathname === '' || location.pathname === '/') {
  //       return <Redirect to={'/media-list'} />;
  //     }
  //   }
  // }, [])


  return (
    <React.Fragment>
      <Switch>
        {/* <RestrictedRoute path="/task-list" component={TaskList} />
        <RestrictedRoute path="/settings" component={SettingsPage} />
        <RestrictedRoute path="/users" component={UsersPage} /> */}
        {/* Public Paths */}

        <Route path="/" exact component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/user-register" component={UserRegistration} />
        <Route path="/logout" component={Logout} />

        <Route path="/settings" component={Settings} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/affiliates" component={Affiliates} />
        <Route path="/affiliate-groups" component={AffiliateGroups} />
        <Route path="/affiliate/:id" component={AffiliateView} />
        <Route path="/spots" component={Spots} />
        <Route path="/users" component={Users} />
        <Route path="/played/:searchType/:searchValue" component={AsPlayed} />
        {/* <Route path="/spots/:id" component={SpotView} /> */}

        {/* fallback Url */}
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(actions, dispath)
});

export default connect(null, mapDispatchToProps)(Routes);
