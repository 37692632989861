import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { TextField, Button, Paper, Grid, Switch, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { settings as settingsState } from 'atoms/atoms';
import { useRecoilState } from 'recoil';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        width: '100%',
        padding: 15,
        marginTop: 40,
    }
}));

const Settings = () => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [settings, setSettings] = useRecoilState(settingsState);
    const [fields, setFields] = useState(settings);

    const [serials, setSerials] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const settingsFetch = await axios.post('/api/settings/read');
                const serialsFetch = await axios.get('/api/valid-serials/user-list');

                setFields(settingsFetch?.data[0]);
                setSettings(settingsFetch?.data[0]);
                setSerials(serialsFetch.data);
            } catch (err) {
                console.log('Unable to get settings', err);
                enqueueSnackbar('Was unable to get settings from server.')
            }
        })();
    }, []);

    const save = async () => {
        try {
            //Remove _id because this is not modifiable
            delete fields._id;

            await axios.post('/api/settings/update', {
                where: {},
                object: fields,
            });

            enqueueSnackbar('Successfully saved settings.');
        } catch (err) {
            console.log('Was unable to save settings', err);
            enqueueSnackbar('Was unable to save settings.');
        }
    }

    const onTextChange = e => {
        setFields({ ...fields, [e.target.name]: e.target.value });
    }

    const onCheckedChange = (e, c) => {
        setFields({ ...fields, [e.target.name]: c });
    }

    return (
        <PageContainer>
            <Grid justify='center' alignContent='center' container>
                <div style={{ maxWidth: '850px', width: '100%' }}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextField
                                        className={classes.formControl}
                                        type='text'
                                        name='firstName'
                                        label='First Name'
                                        value={fields?.firstName || ''}
                                        onChange={onTextChange}
                                        autoComplete='off'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        className={classes.formControl}
                                        type='text'
                                        name='lastName'
                                        label='Last Name'
                                        value={fields?.lastName || ''}
                                        onChange={onTextChange}
                                        autoComplete='off'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        className={classes.formControl}
                                        type='text'
                                        name='email'
                                        label='Admin Email'
                                        value={fields?.email || ''}
                                        onChange={onTextChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        className={classes.formControl}
                                        type='text'
                                        name='phone'
                                        label='Mobile Phone (+1XXXXXXXXXX)'
                                        value={fields?.phone || ''}
                                        onChange={onTextChange}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            Imperial
                            <Switch
                                checked={fields?.imperial || false}
                                onChange={onCheckedChange}
                                name="imperial"
                                color="primary"
                            />
                            Metric

                            <br />
                            <InputLabel style={{ textAlign: 'left' }}>Language</InputLabel>
                            <Select
                                className={classes.formControl}
                                name='language'
                                onChange={onTextChange}
                                value={fields?.language || 'en-us'}
                            >
                                <MenuItem value={'en-us'}>English</MenuItem>
                                <MenuItem value={'spanish'}>Español</MenuItem>
                            </Select>
                        </Paper>
                    </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container>
                            <Grid item xs={4} style={{textAlign: 'center', margin: 'auto'}}>
                                <h4>Licensed Devices</h4>
                                <h2>{serials?.length || ''}</h2>
                            </Grid>
                            <Grid item xs={8} style={{textAlign: 'center'}}>
                                    <p>
                                        ATMOS Traffic Portal is licensed on a per device basis.
                                        If you wish to order additional units or license your account to
                                        utilize a device already in your possession, click the button below
                                        to visit The Billing Portal.
                                </p>
                                <Button
                                    className={'square-button'}
                                    onClick={() => { window.open('https://billing.summittechgroup.com/'); }}
                                >
                                    Manage My Subscription
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container>
                            <Grid item xs={4} style={{textAlign: 'center', margin: 'auto'}}>
                                Placeholder image
                            </Grid>
                                <Grid item xs={8} style={{ textAlign: 'center' }}>
                                    <p>
                                        For routine support and customer support,
                                        click below to create a
                                        support ticket.
                                    </p>
                                <Button
                                    className={'square-button'}
                                    onClick={() => { window.open('https://www.summittechgroup.com/support'); }}
                                >
                                    Contact Support
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Button
                        className={'square-button'}
                        startIcon={<Save />}
                        onClick={() => { save(); }}
                    >
                        Save
                    </Button>
                </Grid>
                </div>
            </Grid>
        </PageContainer>
    );
}

export default Settings;