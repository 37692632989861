import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import React, { useEffect } from 'react';
import TableIcons from '../TableIcons';
import MaterialTable from 'material-table';
import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { withRouter } from 'react-router';
import axios from 'axios';
import SpotsTable from './SpotsTable';

const Spots = props => {
    return (
        <PageContainer>
          <SpotsTable />
        </PageContainer>
    );
}

export default  withRouter(Spots);