import React from 'react';
import dateFormat from 'services/DateFormat';

const DateFormat = ({date, time}) => {
    return (
        <>
            {
                dateFormat(date, time)
            }
        </>
    );
}

export default DateFormat;