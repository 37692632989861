/// RaviB Requried references for redux
import React from 'react';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from "../../../redux/actions";
import CircularProgress from '@material-ui/core/CircularProgress';
//// End of required reference for redux

import { Avatar, makeStyles, CssBaseline, Link, Grid, Box, Typography, Container, Paper } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withSnackbar } from 'notistack';
import CmtImage from '@coremat/CmtImage';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Sidecar © '}
            <Link color="inherit" href="https://www.summittechgroup.com/">
                Summit Tech
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(6)
    },
    paper: {
        marginTop: theme.spacing(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    circularProgressRoot: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));


const Logout = (props) => {
    const classes = useStyles();

    useEffect(() => {
        props.actions.signOutUser()
    }, [props.history])

    const authState = useSelector(state => state.authState.user);

    console.log("authState", authState);

    useEffect(() => {
        if (!authState.data) {
            props.history.push('/login')
        }
    }, [authState])


    const logoUrl = '/images/logo-white.png';

    return (

        <Container component="main" maxWidth="xs">
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >

                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.root}>
                        <CssBaseline />
                        <br />
                        <CmtImage src={logoUrl} alt="Side car cloud" />
                        <br />
                        <br />

                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>


                        <br />
                        <br />

                        <Box className={classes.circularProgressRoot}>
                            <CircularProgress />
                        </Box>

                        <br />
                        <br />

                        <Typography component="h1" variant="h5">
                            Signing out, please wait.
                        </Typography>

                    </Paper >
                </Grid>
            </Grid>
        </Container >

    );
}

//// RaviB binding actions with the view, this will enable actions access on the page
const mapDispatchToProps = (dispath) => ({
    actions: bindActionCreators(actions, dispath)
});

export default connect(null, mapDispatchToProps)(withSnackbar(Logout));
//// End of Binding actions


///// Note: this should be always be done at Parent most view only, we should avoid binding sub components with actions