import React, { useEffect } from 'react';
import TableIcons from '../TableIcons';
import MaterialTable from 'material-table';
import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import axios from 'axios';
import DeleteForm from '../Components/DeleteForm';
import UserEditForm from './UserEditForm';

const Users = props => {

    const [users, setUsers] = useState([]);

    const [editFormOpen, setEditFormOpen] = useState(false);
    const [deleteFormOpen, setDeleteFormOpen] = useState(false);
    const [currentEditObject, setCurrentEditObject] = useState({});

    const openEditForm = rowData => {
        setCurrentEditObject(rowData);
        setEditFormOpen(true);
    }

    const openDeleteForm = rowData => {
        setCurrentEditObject(rowData);
        setDeleteFormOpen(true);
    }

    const load = async () => {
        try {
            const usersFetch = await axios.get('/api/users/list');
            setUsers(usersFetch.data);
        } catch (err) {
            console.log('Could not load', err);
        }
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <>
            <MaterialTable
                icons={TableIcons}
                columns={[
                    {
                        title: 'Edit',
                        render: rowData => {
                            return (
                                <div>
                                    <Button
                                        className={'rounded-button'}
                                        onClick={() => { openEditForm(rowData); }}
                                        startIcon={<Edit />}
                                    />
                                </div>
                            )
                        }
                    },
                    { title: 'Username', field: 'username' },
                    { title: 'Group', field: 'userGroup' },
                    { title: 'Admin', field: 'isAdmin', render: data => data?.isAdmin ? <>Yes</> : <>No</>},
                    {
                        title: 'Delete',
                        render: rowData => {
                            return (
                                <div>
                                    <Button
                                        className={'rounded-button'}
                                        onClick={() => { openDeleteForm(rowData); }}
                                        startIcon={<Delete />}
                                    />
                                </div>
                            )
                        }
                    },
                ]}
                data={users}
                title={`Users`}
            />

            <Dialog open={editFormOpen} onClose={() => { setEditFormOpen(false); }}>
                <DialogTitle id="form-dialog-title">Modify User</DialogTitle>
                <DialogContent>
                    <UserEditForm
                        editObject={currentEditObject}
                        saveCallback={() => {
                            setEditFormOpen(false);
                            load();
                        }}
                    />
                </DialogContent>
            </Dialog>

            <Dialog open={deleteFormOpen} onClose={() => { setDeleteFormOpen(false); }}>
                <DialogTitle id="form-dialog-title">Delete Spot</DialogTitle>
                <DialogContent>
                    <DeleteForm
                        deleteItem={currentEditObject?.username}
                        deleteAction={async () => {
                            await axios.post('/api/users/delete', { where: { _id: currentEditObject._id } });
                        }}
                        saveCallback={() => {
                            setDeleteFormOpen(false);
                            load();
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default Users;