import React from 'react';
import { Paper, Grid } from '@material-ui/core';

const DashboardCard = props => {
    return (
        <Paper style={{backgroundColor: props?.backgroundColor || 'white'}}>
            <Grid container style={{padding: 15}}>
                <Grid item xs={8}>
                    <h1>{props.header}</h1>
                    {props.title}
                </Grid>
                <Grid item xs={4} style={{textAlign: 'center', margin: 'auto'}}>
                    {props?.icon || <></>}
                </Grid>
            </Grid>
        </Paper>
    );
}

export default DashboardCard;