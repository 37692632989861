
export default (fields, setFields, name, data) => {
    //Get current field value
    const current = fields[name];

    //Filter array of data
    const updated = current.filter(i => i != data);

    setFields({ ...fields, [name]: updated });
}
