import * as actionTypes from '../actions/actionTypes';

//// RaviB initial state of reducer, as the application start dispatching actions reducer will keep updating values in below switch cases

const INITIAL_STATE = {
    user: {
        data: null,
        error: null,
        loading: false
    }
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case actionTypes.LOGIN_REQUEST: {
            return {
                ...state, //// RaviB Note this is important, reducer always create new object so if its not mutated, it will clear other values which was already in state
                user: {
                    data: null,
                    error: null,
                    loading: true
                }
            };
        }

        case actionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                user: {
                    data: action.payload,
                    error: null,
                    loading: false
                }
            };
        }

        case actionTypes.LOGIN_FAILURE: {
            return {
                ...state,
                user: {
                    data: null,
                    error: action.payload,
                    loading: false
                }
            };
        }

        case actionTypes.LOGOUT_REQUEST: {
            return {
                ...state, //// RaviB Note this is important, reducer always create new object so if its not mutated, it will clear other values which was already in state
                user: {
                    data: null,
                    error: null,
                    loading: true
                }
            };
        }

        case actionTypes.LOGOUT_SUCCESS: {
            return {
                ...state,
                user: {
                    data: null,
                    error: null,
                    loading: false
                }
            };
        }

        case actionTypes.LOGOUT_FAILURE: {
            return {
                ...state,
                user: {
                    data: null,
                    error: action.payload,
                    loading: false
                }
            };
        }

        default:
            return state;
    }
};

export default authReducer;