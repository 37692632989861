import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, MenuItem, MenuList, Paper, Popover, Typography } from '@material-ui/core';
import actions from "../../../../redux/actions";
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch } from 'react-redux';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    // RaviB Sidebar Mini Configs
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 75,
      paddingTop: 24,
      opacity: 1,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: theme.palette.sidebar.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSettingsClick = () => {
    history.push('/settings');
  }

  const onLogoutClick = () => {
    props.actions.signOutUser()
  };

  const authState = useSelector(state => state.authState.user);

  useEffect(() => {
    if (!authState?.data?.username) {
      handlePopoverClose();
    }
  }, [authState.data])
  return (
    <Box className={classes.root}>
      <CmtAvatar src={'/images/account.png'} alt="User Avatar" />
      <Box className={classes.userInfo} onClick={handlePopoverOpen}>
        <Box className="pointer" display="flex" justifyContent="space-between" alignItems="flex-end">
          <Box mr={2}>
            <Typography className={classes.userTitle} component="h3" variant="h6">
              {''}
            </Typography>
            <Typography className={classes.userSubTitle}>{authState?.data?.username}</Typography>
          </Box>
          <ArrowDropDownIcon />
        </Box>
      </Box>

      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}>
          <Paper elevation={8}>
            <MenuList>
              <MenuItem onClick={handleSettingsClick}>
                <SettingsIcon />
                <Box ml={2}>Settings</Box>
              </MenuItem>
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <Box ml={2}>Logout</Box>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}
    </Box>
  );
};

const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(actions, dispath)
});

export default connect(null, mapDispatchToProps)(SidebarHeader);

