import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import React, { useEffect } from 'react';
import TableIcons from '../TableIcons';
import MaterialTable from 'material-table';
import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Delete, Visibility } from '@material-ui/icons';
import { withRouter } from 'react-router';
import axios from 'axios';
import AffiliateEditForm from './AffiliateEditForm';
import { affiliatesAtom, isAdmin as isAdminAtom } from 'atoms/atoms';
import { useRecoilState } from 'recoil';
import ThemedButton from '../Components/ThemedButton';
import DeleteForm from '../Components/DeleteForm';

const Affiliates = props => {

    const [editFormOpen, setEditFormOpen] = useState(false);
    const [deleteFormOpen, setDeleteFormOpen] = useState(false);
    const [currentEditObject, setCurrentEditObject] = useState({});

    const [affiliates, setAffiliates] = useRecoilState(affiliatesAtom);
    const [isAdmin, setIsAdmin] = useRecoilState(isAdminAtom);

    const load = async () => {
        const affiliateFetch = await axios.get('/api/affiliates/list');
        setAffiliates(affiliateFetch.data);

        const adminFetch = await axios.get('/api/users/is-admin');
        setIsAdmin(adminFetch.data);
    }

    useEffect(() => {
        load();
    }, []);

    const getTableColumns = () => {
        let columns = [
            {
                title: 'View',
                render: rowData => {
                    return (
                        <div>
                            <Button
                                className={'rounded-button'}
                                onClick={() => { props.history.push(`/affiliate/${rowData._id}`) }}
                                startIcon={<Visibility />}
                            />
                        </div>
                    )
                }
            },
            { title: 'Call Sign', field: 'callSign' },
            { title: 'Account Exec', field: 'accountExec' },
            {
                title: 'As-played',
                render: rowData => {
                    return (
                        <Button
                            className={'rounded-button'}
                            onClick={() => { props.history.push(`/played/affiliate/${rowData.callSign}`) }}
                            startIcon={<Visibility />}
                        />
                    )
                }
            },
        ];

        // Only show these columns if we are an admin
        return isAdmin ? [...columns,
        {
            title: 'User Group',
            field: 'userGroup',
        },
        {
            title: 'Delete',
            render: rowData => {
                return (
                    <div>
                        <Button
                            className={'rounded-button'}
                            onClick={() => {
                                setCurrentEditObject(rowData);
                                setDeleteFormOpen(true);
                            }}
                            startIcon={<Delete />}
                        />
                    </div>
                )
            },
        },
        ] : columns;
    }

    return (
        <PageContainer>
            {
                isAdmin ? (
                    <ThemedButton
                        onClick={() => { setEditFormOpen(true); }}
                    >
                        Add Affiliate
                    </ThemedButton>
                ) : <></>
            }
            <MaterialTable
                icons={TableIcons}
                columns={getTableColumns()}
                data={JSON.parse(JSON.stringify(affiliates))}
                title='Affiliates'
            />

            <Dialog open={editFormOpen} onClose={() => { setEditFormOpen(false); }}>
                <DialogTitle id="form-dialog-title">Modify Affiliate</DialogTitle>
                <DialogContent>
                    <AffiliateEditForm
                        affiliate={currentEditObject}
                        saveCallback={() => {
                            setEditFormOpen(false);
                            load();
                        }}
                    />
                </DialogContent>
            </Dialog>

            <Dialog open={deleteFormOpen} onClose={() => { setDeleteFormOpen(false); }}>
                <DialogTitle id="form-dialog-title">Delete Affiliate</DialogTitle>
                <DialogContent>
                    <DeleteForm
                        deleteItem={currentEditObject?.callSign}
                        deleteAction={async () => {
                            await axios.post('/api/affiliates/delete', { where: { _id: currentEditObject._id } });
                        }}
                        saveCallback={() => {
                            setDeleteFormOpen(false);
                            load();
                        }}
                    />
                </DialogContent>
            </Dialog>
        </PageContainer>
    );
}

export default  withRouter(Affiliates);