
const checkDigit = t => {
    return (t < 10) ? `0${t}` : t;
}

const getTimeString = date => {
    let hour = date.getHours();

    const a = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'  

    hour = checkDigit(hour);
    let minute = checkDigit(date.getMinutes());
    let second = checkDigit(date.getSeconds());

    return `${hour}:${minute}:${second} ${a}`;
}

const getDayString = date => {
    const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    const dateString = `${MONTHS[date.getMonth()]}/${date.getDate()}/${date.getFullYear()}`;

    return dateString;
}

export default (date, time) => {
    try {
        const dateObject = new Date(date);
        return `${getDayString(dateObject)}${time ? ` ${getTimeString(dateObject)}` : ''}`;
    } catch (err) {
        console.log('Could not format date', err);
    }

    return '';
}
